// src/components/Home.js

import React, { useEffect, useRef } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import './css/Home.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import image1 from '../assets/carousel/image1.png'; // Ensure the images are placed in src/assets/
import image2 from '../assets/carousel/image2.png';
import image3 from '../assets/carousel/image3.png';
import featuredImage from '../assets/carousel/image1.png'; // The featured image for the new section
import decorImage from '../assets/sideimage.png';

import person1 from '../assets/testimonial/person.jpeg';
import person2 from '../assets/testimonial/person.jpeg';
import person3 from '../assets/testimonial/person.jpeg';

import ContactSection from './ContactSection';
import Footer from './Footer';


const Home = () => {
  const headingRef = useRef(null);
  const sectionRef = useRef(null);
  const featuredSectionRef = useRef(null);
  const featureProductRef = useRef(null);
  const newSectionRef = useRef(null);
  const testimonialsRef = useRef(null);


  useEffect(() => {
    document.title = "Eve's Garden | Event Management Company In North East India";
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    if (featuredSectionRef.current) {
      observer.observe(featuredSectionRef.current);
    }

    if (featureProductRef.current) {
      observer.observe(featureProductRef.current);
    }

    if (newSectionRef.current) {
      observer.observe(newSectionRef.current);
    }

    if (testimonialsRef.current) {
      observer.observe(testimonialsRef.current);
    }
  }, []);

  const testimonialSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <Container fluid className="p-0">

      <Carousel
        fade
        interval={3000}
        className="custom-carousel shadow-right"
        prevIcon={<span className="custom-prev-icon" aria-hidden="true"></span>}
        nextIcon={<span className="custom-next-icon" aria-hidden="true"></span>}
        prevLabel=""
        nextLabel=""
      >
        <Carousel.Item>
          <img className="d-block w-100 carousel-image" src={image1} alt="Corporate Event Management Guwahati" />
          <Carousel.Caption className="carousel-caption-custom">
            <h3>Corporate Event Management</h3>
            <p>We organize professional corporate events in North East India.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 carousel-image" src={image2} alt="Wedding Planners Guwahati" />
          <Carousel.Caption className="carousel-caption-custom">
            <h3>Wedding Planners</h3>
            <p>Plan your dream wedding with the best Us in North East India.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 carousel-image" src={image3} alt="Birthday Party Organizers Guwahati" />
          <Carousel.Caption className="carousel-caption-custom">
            <h3>Birthday Party Organizers</h3>
            <p>Make your birthday celebrations memorable with our expert organizers.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
{/* 
      <section className="centered-caption">
        <h2>We Are Getting A Heaven Ready For Your Wonderful Day</h2>
      </section> */}

      <section className="wedding-section mt-5">
        <div ref={headingRef} className="wedding-heading">
          <h2>Design Exceptional Wedding</h2>
          <div className="underline"></div>
          <p>
            Our team of expert wedding planners ensures that your wedding day is as unique and special as you are. We take care of every detail, from the grandest gestures to the smallest touches, to make your day unforgettable.
          </p>
        </div>
        <Row ref={sectionRef} className="wedding-images">
          <Col md={4} className="wedding-image-container" style={{ backgroundImage: `url(${image1})` }}>
            <div className="wedding-text">
              <h3>Wedding Planning</h3>
              <p>We provide comprehensive wedding planning services to make your special day perfect.</p>
            </div>
          </Col>
          <Col md={4} className="wedding-image-container" style={{ backgroundImage: `url(${image2})` }}>
            <div className="wedding-text">
              <h3>Venue Decoration</h3>
              <p>Our team decorates your wedding venue to match your style and preferences.</p>
            </div>
          </Col>
          <Col md={4} className="wedding-image-container" style={{ backgroundImage: `url(${image3})` }}>
            <div className="wedding-text">
              <h3>Catering Services</h3>
              <p>We offer top-notch catering services to ensure your guests enjoy delicious meals.</p>
            </div>
          </Col>
        </Row>
      </section>

      <section ref={featuredSectionRef} className="featured-section mt-0" style={{ backgroundImage: `url(${featuredImage})` }}>
        <Row className="align-items-center">
          <Col md={6} className="featured-image-container">
            {/* The image container is now empty */}
          </Col>
          <Col md={6} className="featured-text-container">
            <h3>About</h3>
            <h2>Eve's Garden</h2>
            <p style={{textAlign: 'justify'}}>Welcome to Eve’s Garden , where your dream wedding becomes a reality. We are a team of passionate and experienced wedding planners dedicated to
creating unforgettable celebrations of love.</p>
            <Link to="/about" className="btn-learn-more">More Info</Link>
          </Col>
        </Row>
      </section>

      <section ref={featureProductRef} className="feature-product-section mt-0">
        <div className="feature-product-heading">
          <h2>Feature Services</h2>
          <div className="underline"></div>
          <p>Explore our exclusive range of services that are designed to enhance your event experience.</p>
        </div>
        <Row className="feature-product-images">
          <Col md={4} className="feature-product-image-container">
            <img src={image1} alt="Entertainment Coordination" className="feature-product-image" />
            <div className="feature-product-text">
              <h3>Entertainment Coordination</h3>
              <p>From live music and DJs to traditional dance performances and entertainers</p>
              <Link to="/services#entertainment" className="btn-learn-more">Learn More</Link>
            </div>
          </Col>
          <Col md={4} className="feature-product-image-container middle-image">
            <img src={image2} alt="Logistics and Transportation" className="feature-product-image" />
            <div className="feature-product-text">
              <h3>Logistics and Transportation</h3>
              <p>We manage all aspects of logistics and transportation, ensuring that your guests arrive</p>
              <Link to="/services#logistic" className="btn-learn-more">Learn More</Link>
            </div>
          </Col>
          <Col md={4} className="feature-product-image-container" >
            <img src={image3} alt="Sightseeing Tours" className="feature-product-image" />
            <div className="feature-product-text">
              <h3>Sightseeing Tours</h3>
              <p>For destination weddings and events, we offer guided sightseeing tours</p>
              <Link to="/services#sightseening" className="btn-learn-more">Learn More</Link>
            </div>
          </Col>
        </Row>
      </section>

      <section ref={newSectionRef} className="new-section mt-0" style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${decorImage})`,
        backgroundSize: 'cover', // Ensures the image covers the entire section
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat', // Prevents the image from repeating
        height: '100%', // Adjust the height as needed
        width: '100%', // Ensures the section takes full width
      }}>
        <Row className="align-items-center">
          <Col md={6} className="new-text-container">
            <h3>Exclusive Services</h3>
            <h2>Event Planning & Design</h2>
            <p>We offer a wide range of services to cater to all your event planning and design needs.</p>
            <p>Our team ensures that every detail is taken care of, providing you with a seamless and memorable experience.</p>
          </Col>
          <Col md={6} className="new-image-container">

          </Col>
        </Row>
      </section>

      <section ref={testimonialsRef} className="testimonials-section">
        <h2>Testimonials</h2>
        <Slider {...testimonialSettings} className="testimonials-carousel">
          <div className="testimonial-item">
            <div className="testimonial-avatar">
              <img src={person1} alt="John Doe" />
            </div>
            <div className="testimonial-content">
              <h3>John Doe</h3>
              <p>“Eve's Garden provided exceptional service and made our event truly memorable. Their attention to detail was outstanding!”</p>
            </div>
          </div>
          <div className="testimonial-item">
            <div className="testimonial-avatar">
              <img src={person2} alt="Jane Smith" />
            </div>
            <div className="testimonial-content">
              <h3>Jane Smith</h3>
              <p>“The team at Eve's Garden went above and beyond to ensure our wedding day was perfect. Highly recommend!”</p>
            </div>
          </div>
          <div className="testimonial-item">
            <div className="testimonial-avatar">
              <img src={person3} alt="Sam Johnson" />
            </div>
            <div className="testimonial-content">
              <h3>Sam Johnson</h3>
              <p>“Professional, creative, and dedicated – Eve's Garden exceeded all our expectations. Thank you!”</p>
            </div>
          </div>
        </Slider>
      </section>

      {/* <section className="centered-welcome">
        <h2>We Welcome You To World Of Decorations</h2>
      </section> */}

      <ContactSection />
      <Footer />
    </Container>
  );
};

export default Home;
