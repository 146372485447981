// src/components/ContactSection.js

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import './css/ContactSection.css';
import MapComponent from './MapComponent';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    title: 'message',
    email: '',
    message: '',
    contact: '',
    captcha: '',
  });

  const [isCaptchaValid, setCaptchaValid] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateCaptcha = (e) => {
    if (parseInt(e.target.value) === 12) { // 6 + 6 = 12
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (isCaptchaValid) {
      emailjs.sendForm('service_u9mxgnr', 'template_0tydgwg', e.target, 'VuqJSrRb4EHLsWMG7')
        .then((result) => {
          Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully',
            showConfirmButton: false,
            timer: 1500
          });
          setFormData({
            name: '',
            email: '',
            contact: '',
            message: '',
            captcha: '',
          });
        }, (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong! Please try again later.',
          });
        });
      e.target.reset();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Captcha',
        text: 'Please complete the CAPTCHA correctly.',
      });
    }
  };

  return (
    <Container className="contact-section">
      <Row>
        <Col md={6}>
          <h2>Reach Us</h2>
          <p><strong>Address</strong></p>
          <p>Eve's Garden,<br />
            House no 8, Kandura Mahajan path ,<br />
            Senmela, Lakhara Guwahati-40<br />
            Assam, India
          </p>
          <p><strong>Contact</strong></p>
          <p>+91 70868 58128<br />
            info@evesgarden.in</p>
          <p><strong>Visit</strong></p>
          <p>Mon-Sat: 09:00 A.M. – 05:00 </p>
          <br/>
          
        </Col>
        <Col md={6}>
          <h2>Get In Touch</h2>
          <Form onSubmit={sendEmail}>
            <Form.Group controlId="formName">
              <Form.Control
                type="text"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Control
                type="email"
                placeholder="Email Address"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formContact">
              <Form.Control
                type="text"
                placeholder="Contact Number"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formCaptcha">
              <Row>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    placeholder="6 + 6 ="
                    onChange={validateCaptcha}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={!isCaptchaValid}>
              Send Request
            </Button>
          </Form>
        </Col>

        <MapComponent />
      </Row>
    </Container>
  );
};

export default ContactSection;
