// src/components/Services.js

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import './css/Services.css';
import serviceImage1 from '../assets/services/services1.jpeg'; // Adjust the path to your images
import serviceImage2 from '../assets/services/services2.jpeg';
import serviceImage3 from '../assets/services/services3.jpeg';
import serviceImage4 from '../assets/services/services4.jpeg';
import serviceImage5 from '../assets/services/services5.jpeg';
import serviceImage6 from '../assets/services/services6.jpeg';
import serviceHeaderImage from '../assets/services/services-hero.png'; // Header image path
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const Services = () => {
  useEffect(() => {
    document.title = "Services - Eve's Garden";
  }, []);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const { ref: sectionRef, inView: sectionInView } = useInView({ triggerOnce: true });
  const { ref: imageRef1, inView: imageInView1 } = useInView({ triggerOnce: true });
  const { ref: imageRef2, inView: imageInView2 } = useInView({ triggerOnce: true });
  const { ref: imageRef3, inView: imageInView3 } = useInView({ triggerOnce: true });
  const { ref: imageRef4, inView: imageInView4 } = useInView({ triggerOnce: true });
  const { ref: imageRef5, inView: imageInView5 } = useInView({ triggerOnce: true });
  const { ref: imageRef6, inView: imageInView6 } = useInView({ triggerOnce: true });

  return (
    <>
      <Container fluid className="services-header-section" style={{ backgroundImage: `url(${serviceHeaderImage})` }}>
        <div className="services-header-overlay">
          <h1>Our Services</h1>
        </div>
      </Container>

      <Container className="services-specialties-section">
        <div ref={sectionRef} className={`section-heading ${sectionInView ? 'animate' : ''}`}>
          <h2>Our Specialties</h2>
          <p>
            Throughout your journey, we come across different wedding stories and cultures. Each one is unique, and we strive to make it special for every couple. From the initial planning to the final execution, our team ensures a seamless and memorable event.
          </p>
        </div>
        <Row>
          <Col md={4} ref={imageRef1} className={`service-item ${imageInView1 ? 'animate' : ''}`}>
            <img src={serviceImage1} alt="Wedding Planning" className="img-fluid" />
            <h3>Wedding Planning</h3>
            <p style={{ textAlign: 'justify' }}>
              The Eve's Garden Wedding Planners specialize in
              comprehensive wedding planning services. Our team
              is skilled in organizing and coordinating all aspects of
              your wedding, including creating moodboards and
              themes, designing the wedding in 2D, and more.
            </p>
          </Col>
          <Col md={4} ref={imageRef2} className={`service-item ${imageInView2 ? 'animate' : ''}`}>
            <img src={serviceImage2} alt="Event Management" className="img-fluid" />
            <h3>Event Management</h3>
            <p>
              Through a blend of innovation and meticulous
              execution, we excel in both contemporary and
              traditional event management. We wholeheartedly
              embrace our clients' concepts and transform them into
              remarkable realities, exceeding expectations at every
              tur
            </p>
          </Col>
          <Col md={4} ref={imageRef3} className={`service-item ${imageInView3 ? 'animate' : ''}`}>
            <img src={serviceImage3} alt="Experiences" className="img-fluid" />
            <h3>Experiences</h3>
            <p>
              We provide unique experiences that reflect your story, adding a personal touch to your special day.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} ref={imageRef4} className={`service-item ${imageInView4 ? 'animate' : ''}`}>
            <img src={serviceImage4} alt="Catering Services:g" className="img-fluid" />
            <h3>Catering Services:</h3>
            <p>
              Delight your guests with exquisite culinary experiences. Our catering team offers a diverse menu, featuring local delicacies and international cuisines, ensuring that your event is a gastronomic delight.
            </p>
          </Col>
          <Col md={4} ref={imageRef5} className={`service-item ${imageInView5 ? 'animate' : ''}`}>
            <img src={serviceImage5} alt="Car Decoration" className="img-fluid" />
            <h3>Car Decoration</h3>
            <p>
              Arrive in style with our specialized car decoration services. We create stunning car decor that complements your event theme and adds an extra touch of glamour to your arrival.
            </p>
          </Col>
          <Col md={4} ref={imageRef6} className={`service-item ${imageInView6 ? 'animate' : ''}`} id='sightseening'>
            <img src={serviceImage6} alt="Sightseeing Tours" className="img-fluid" />
            <h3>Sightseeing Tours</h3>
            <p>
              For destination weddings and events, we offer guided sightseeing tours of the beautiful landscapes and cultural landmarks of Northeast India. Experience the charm of Assam and beyond with our curated tour packages.
            </p>
          </Col>
          <Col md={4} ref={imageRef6} className={`service-item ${imageInView6 ? 'animate' : ''}`} id='entertainment'>
            <img src={serviceImage6} alt="Entertainment Coordination" className="img-fluid" />
            <h3>Entertainment Coordination</h3>
            <p>
              From live music and DJs to traditional dance performances and entertainers, we provide a wide range of entertainment options to keep your guests engaged and entertained throughout the event.
            </p>
          </Col>
          <Col md={4} ref={imageRef6} className={`service-item ${imageInView6 ? 'animate' : ''}`}>
            <img src={serviceImage6} alt="Photography and Videography" className="img-fluid" />
            <h3>Photography and Videography</h3>
            <p>
              Capture every precious moment with our professional photography and videography services. Our experienced photographers and videographers ensure that every smile, tear, and laugh is beautifully documented.
            </p>
          </Col>
          <Col md={4} ref={imageRef6} className={`service-item ${imageInView6 ? 'animate' : ''}`} id='logistic'>
            <img src={serviceImage6} alt="Logistics and Transportation" className="img-fluid" />
            <h3>Logistics and Transportation</h3>
            <p>
              We manage all aspects of logistics and transportation, ensuring that your guests arrive and depart comfortably and on time. Our services include shuttle arrangements, guest accommodations, and travel itineraries.
            </p>
          </Col>
        </Row>
        {/* <div className="text-center">
          <Button className="all-services-button">All Services</Button>
        </div> */}
      </Container>

      <Footer />
    </>
  );
};

export default Services;
