// src/components/GetAQuote.js

import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import Footer from './Footer';
import './css/GetAQuote.css';

const GetAQuote = () => {

  useEffect(() => {
    document.title = "Get A Quote - Eve's Garden";
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    title:'quotation request',
    email: '',
    message: '',
    contact: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_u9mxgnr', 'template_0tydgwg', e.target, 'VuqJSrRb4EHLsWMG7')
      .then((result) => {
        Swal.fire({
          icon: 'success',
          title: 'Quote Request Sent Successfully',
          showConfirmButton: false,
          timer: 1500
        });
        setFormData({
          name: '',
          email: '',
          contact:'',
          message: '',
        });
      }, (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong! Please try again later.',
        });
      });
  };

  return (
    <>
      <Container className="get-a-quote-container">
        <h1>Get a Quote</h1>
        <Form onSubmit={sendEmail}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your phone number"
              name="contact"
              value={formData.contact}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formMessage">
            <Form.Label>Event Details</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Provide details about your event"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Container>
      <Footer />
    </>
  );
};

export default GetAQuote;
