// src/components/CookiePolicy.js

import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Footer from './Footer';
import './css/CookiePolicy.css';

const CookiePolicy = () => {
  useEffect(() => {
    document.title = "Cookie Policy - Eve's Garden";
  }, []);
  return (
    <>
      <Container className="cookie-policy-container">
        <h1>Cookie Policy</h1>
        <p>
          This Cookie Policy explains how we use cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.
        </p>

        <h2>What are cookies?</h2>
        <p>
          Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.
        </p>

        <h2>Why do we use cookies?</h2>
        <p>
          We use first-party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our website. Third parties serve cookies through our website for advertising, analytics, and other purposes.
        </p>

        <h2>Types of cookies we use</h2>
        <ul>
          <li>
            <strong>Essential Cookies:</strong> These cookies are strictly necessary to provide you with services available through our website and to use some of its features.
          </li>
          <li>
            <strong>Performance and Functionality Cookies:</strong> These cookies are used to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.
          </li>
          <li>
            <strong>Analytics and Customization Cookies:</strong> These cookies collect information that is used either in aggregate form to help us understand how our website is being used or how effective our marketing campaigns are, or to help us customize our website for you.
          </li>
          <li>
            <strong>Advertising Cookies:</strong> These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.
          </li>
        </ul>

        <h2>Your choices regarding cookies</h2>
        <p>
          You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by clicking on the appropriate opt-out links provided. You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website, though your access to some functionality and areas of our website may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.
        </p>

        <h2>More information</h2>
        <p>
          If you have any questions about our use of cookies or other technologies, please email us at: info@example.com
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default CookiePolicy;
