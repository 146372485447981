// src/components/AboutUs.js

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import './css/AboutUs.css';
import aboutImage1 from '../assets/about/about1.jpeg'; // Adjust the path to your images
import aboutImage2 from '../assets/about/about2.jpeg';
import aboutHeaderImage from '../assets/about/about-header.jpeg'; // Header image path
import Footer from './Footer';

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - Eve's Garden";
  }, []);
  const { ref: sectionRef1, inView: sectionInView1 } = useInView({ triggerOnce: true });
  const { ref: sectionRef2, inView: sectionInView2 } = useInView({ triggerOnce: true });
  const { ref: sectionRef3, inView: sectionInView3 } = useInView({ triggerOnce: true });

  return (
    <>
      <Container fluid className="about-header-section" style={{ backgroundImage: `url(${aboutHeaderImage})` }}>
        <div className="about-header-overlay">
          <h1>About Us</h1>
        </div>
      </Container>

      <Container className="about-content-section">
        <Row ref={sectionRef1} className={`about-section ${sectionInView1 ? 'animate' : ''}`}>
          <Col md={6} className="about-image">
            <img src={aboutImage1} alt="A Journey Worth Memorable With Us" className="img-fluid" />
          </Col>
          <Col md={6} className="about-text">
            <h2>A Journey Worth Memorable With Us</h2>
            <p>
              Our journey has been filled with memorable experiences, creating beautiful moments and crafting unique stories for each couple.
            </p>
            <p>
              We take pride in our ability to personalize every wedding and event, ensuring that it reflects the couple's vision and style.
            </p>
          </Col>
        </Row>

        <Row ref={sectionRef2} className={`about-section ${sectionInView2 ? 'animate' : ''}`}>
          <Col md={6} className="about-text">
            <h2>Our Story</h2>
            <p style={{textAlign:'justify'}}>
              Founded with a vision to revolutionize the event planning industry in Northeast India, Eve's Garden has grown into a trusted name for elegance and excellence.
            </p>
            <p style={{textAlign:'justify'}}>
              Our journey began in Guwahati, the vibrant gateway to the Northeast, where we set out to create magical experiences for every occasion. Over the years, we have meticulously crafted weddings, corporate events, and social gatherings that leave lasting impressions.
            </p>

          </Col>
          <Col md={6} className="about-image">
            <img src={aboutImage2} alt="Since 2009" className="img-fluid" />
          </Col>
        </Row>

        <Row ref={sectionRef3} className={`about-section ${sectionInView1 ? 'animate' : ''}`}>
          <Col md={6} className="about-image">
            <img src={aboutImage1} alt="Why Us" className="img-fluid" />
          </Col>
          <Col md={6} className="about-text">
            <h2>Why Choose Us</h2>
            <p style={{ textAlign: 'justify' }}>
              <strong>Personalized Approach:</strong>  We believe every event is unique, and we take the time to understand your vision and preferences. Our personalized approach ensures that your event reflects your style and personality.
            </p>
            <p>
              <strong>Experienced Team:</strong> Our team of seasoned professionals brings a wealth of experience and expertise to every project. From planners and designers to chefs and coordinators, we work harmoniously to deliver excellence.
            </p>
          </Col>
        </Row>

        <Row ref={sectionRef3} className={`about-section ${sectionInView3 ? 'animate' : ''}`}>
          <Col md={12} className="text-center">
            <h2>Our Story</h2>
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Replace with your video URL
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default AboutUs;
