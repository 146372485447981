// src/components/Portfolio.js

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './css/Portfolio.css';
import album1 from '../assets/portfolio/album/1/Album1.jpeg'; // Adjust the path to your images
import album2 from '../assets/portfolio/album/2/Album2.jpeg';
import album3 from '../assets/portfolio/album/3/Album3.jpeg';
import album4 from '../assets/portfolio/album/4/Album4.jpeg';
import album1a from '../assets/portfolio/album/1/Album1a.jpeg';
import album1b from '../assets/portfolio/album/1/Album1b.jpeg';
import album2a from '../assets/portfolio/album/2/Album2a.jpeg';
import album2b from '../assets/portfolio/album/2/Album2b.jpeg';
import Footer from './Footer';

const Portfolio = () => {
  useEffect(() => {
    document.title = "Portfolio - Eve's Garden";
  }, []);
  const albumGroups = {
    group1: [
      { original: album1, thumbnail: album1 },
      { original: album1a, thumbnail: album1a },
      { original: album1b, thumbnail: album1b }
    ],
    group2: [
      { original: album2, thumbnail: album2 },
      { original: album2a, thumbnail: album2a },
      { original: album2b, thumbnail: album2b }
    ],
    group3: [
      { original: album3, thumbnail: album3 }
    ],
    group4: [
      { original: album4, thumbnail: album4 }
    ]
  };

  const [isOpen, setIsOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState([]);

  const handleOpenGallery = (group) => {
    setCurrentGroup(albumGroups[group]);
    setIsOpen(true);
  };

  const handleCloseGallery = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Container fluid className="portfolio-hero-section">
        <div className="portfolio-hero-overlay">
          <h1>Portfolio</h1>
          <Button
            href="https://www.instagram.com/eves_garden_wedding_planner_/reels/" // Instagram page URL
            target="_blank"
            rel="noopener noreferrer"
            className="portfolio-hero-button"
          >
            Watch Our Wedding Reels Here
          </Button>
        </div>
      </Container>

      <Container className="portfolio-albums-section">
        <h2 className="text-center">View Our Wedding Albums</h2>
        <Row>
          <Col md={3} className="portfolio-album" onClick={() => handleOpenGallery('group1')}>
            <img src={album1} alt="Lovie & Fred" className="img-fluid" />
            <p className="portfolio-album-caption">Lovie & Fred</p>
          </Col>
          <Col md={3} className="portfolio-album" onClick={() => handleOpenGallery('group2')}>
            <img src={album2} alt="Yohan & Elu" className="img-fluid" />
            <p className="portfolio-album-caption">Yohan & Elu</p>
          </Col>
          <Col md={3} className="portfolio-album" onClick={() => handleOpenGallery('group3')}>
            <img src={album3} alt="Riya & Davis" className="img-fluid" />
            <p className="portfolio-album-caption">Riya & Davis</p>
          </Col>
          <Col md={3} className="portfolio-album" onClick={() => handleOpenGallery('group4')}>
            <img src={album4} alt="Sarath & Priya" className="img-fluid" />
            <p className="portfolio-album-caption">Sarath & Priya</p>
          </Col>
        </Row>
      </Container>

      <Modal show={isOpen} onHide={handleCloseGallery} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={currentGroup} showThumbnails={true} showFullscreenButton={true} />
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default Portfolio;
