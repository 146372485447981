// src/components/AboutUs.js

import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import './css/Contact.css';
import contactHeaderImage from '../assets/contact/contact-header.jpeg'; // Header image path
import ContactSection from './ContactSection';
import Footer from './Footer';

const Contact = () => {
    useEffect(() => {
        document.title = "Contact Us - Eve's Garden";
      }, []);

    const { ref: sectionRef1, inView: sectionInView1 } = useInView({ triggerOnce: true });

    return (
        <>
            <Container fluid className="contactus-header-section" style={{ backgroundImage: `url(${contactHeaderImage})` }}>
                <div className="contactus-header-overlay">
                    <h1>Contact Us</h1>
                </div>
            </Container>

            <Container className="contactus-content-section">
                <Row ref={sectionRef1} className={`contactus-section ${sectionInView1 ? 'animate' : ''}`}>
                    <ContactSection/>
                </Row>
            </Container>

            <Footer />
        </>
    );
};

export default Contact;
