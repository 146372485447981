// src/components/Footer.js

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './css/Footer.css';
import { FaFacebook, FaInstagram, FaYoutube, FaWhatsapp } from 'react-icons/fa';
import logo from '../assets/logo.png'; // Use the appropriate path to your logo

const Footer = () => {
  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col md={5}>
            <img src={logo} alt="St Martin Wedding Planners" className="footer-logo" />
            <p>We have extensive experience in weddings and events. A global event management company that has managed events across the country since 2009. We understand our clients’ emotional needs. Feel free to reach out to us at any time.</p>
            <div className="social-media-icons">
            <a href="https://www.facebook.com/profile.php?id=61559729729022" target="_blank" rel="noopener noreferrer" className="me-2">
                <FaFacebook className="top-bar-icon" />
              </a>
              <a href="https://www.instagram.com/eves_garden_wedding_planner_" target="_blank" rel="noopener noreferrer" className="me-2">
                <FaInstagram className="top-bar-icon" />
              </a>
              <a href="https://www.youtube.com/channel/UCZOdBCGUycHEvDYB60iVpCw" target="_blank" rel="noopener noreferrer" className="me-2">
                <FaYoutube className="top-bar-icon"/>
              </a>
              <a href="https://wa.me/7086858128" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp className="top-bar-icon" />
              </a>
            </div>
          </Col>
          <Col md={2} >
            <h5>Our services</h5>
            <ul>
              <li><Link to="/decor-staging">Decor & Staging</Link></li>
              <li><Link to="/wedding-events">Wedding & Events</Link></li>
              <li><Link to="/wedding-planning">Wedding Planning</Link></li>
              <li><Link to="/luxury-car-rental">Luxury Car Rental</Link></li>
              <li><Link to="/bridal-bouquet">Bridal Bouquet</Link></li>
              <li><Link to="/destination-wedding">Destination Wedding</Link></li>
            </ul>
          </Col>
          <Col md={2}>
            <h5>Navigation</h5>
            <ul>
              <li><Link to="/portfolio">Portfolio</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
              <li><Link to="/quote">Get a Quote</Link></li>
            </ul>
          </Col>
          <Col md={2}>
            <h5>Legal</h5>
            <ul>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/cookie-policy">Cookie Policy</Link></li>
              <li><Link to="/terms-of-service">Terms of Service</Link></li>
              <li><Link to="/disclaimer">Disclaimer</Link></li>
            </ul>
          </Col>
        </Row>
        <hr/>
        <Row className="footer-bottom">
          <Col md={12}>
            <p>©2024 by Eve's Garden Wedding Planners | Powered by <a href="https://evesgarden.in" rel="noopener noreferrer">Eve's Garden</a></p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
