import React from 'react';

const MapComponent = () => {
  return (
    <div style={{ width: '100%', height: '450px', marginTop: '10px' }}>
      <iframe
        title="Eve's Garden Location Map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14330.007769512056!2d91.7464487!3d26.1151722!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5d7f4e7df72f%3A0xa44d5218d58df5b4!2sEve&#39;s%20Garden!5e0!3m2!1sen!2sin!4v1719769145650!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default MapComponent;
