// src/components/Navbar.js

import React from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FaFacebook, FaInstagram, FaEnvelope, FaPhone, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import './css/Navbar.css';
import logo from '../assets/logo.png'; // Ensure the logo is placed in src/assets/

const CustomNavbar = () => {
  return (
    <>
      <div className="top-bar py-2">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col xs={12} md="auto" className="text-center text-md-start mb-2 mb-md-0">
              <FaEnvelope className="me-2 top-bar-icon" /> info@evesgarden.in | <FaPhone className="me-2 top-bar-icon" /> +91-7086858128
            </Col>
            <Col xs={12} md="auto" className="text-center text-md-end">
              <a href="https://www.facebook.com/profile.php?id=61559729729022" target="_blank" rel="noopener noreferrer" className="me-2">
                <FaFacebook className="top-bar-icon" />
              </a>
              <a href="https://www.instagram.com/eves_garden_wedding_planner_" target="_blank" rel="noopener noreferrer" className="me-2">
                <FaInstagram className="top-bar-icon" />
              </a>
              <a href="https://www.youtube.com/channel/UCZOdBCGUycHEvDYB60iVpCw" target="_blank" rel="noopener noreferrer" className="me-2">
                <FaYoutube className="top-bar-icon"/>
              </a>
              <a href="https://wa.me/7086858128" target="_blank" rel="noopener noreferrer"> {/* Update with your WhatsApp number */}
                <FaWhatsapp className="top-bar-icon" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar expand="lg" sticky="top" className="custom-navbar">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img src={logo} alt="Logo" className="navbar-logo" />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/portfolio">
                <Nav.Link>Portfolio</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/services">
                <Nav.Link>Our Services</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link>About Us</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact">
                <Nav.Link>Contact Us</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/quote">
                <Nav.Link>Get A Quote</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
